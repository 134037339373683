import { Box, Container, Grid } from "@mui/material";
import React from "react";

import first from "./1.webp";
import "../../static/optirec.css";
import { StaticImage } from "gatsby-plugin-image";

const Bullets = () => {
  return (
    <Container maxWidth="lg" style={{ marginTop: "5em" }}>
      <Grid item container xs={12} justifyContent={"space-between"}>
        <Grid item container md={5} xs={12}>
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            sx={{
              justifyContent: {
                xs: "space-between",
                sm: "flex-start",
                md: "space-between",
              },
            }}
          >
            <Grid item container xs={2}>
              <StaticImage
                src="./click.svg"
                style={{ maxWidth: "100%" }}
                imgStyle={{ objectFit: "contain" }}
                loading="lazy"
                placeholder="dominantColor"
                formats={["auto", "webp", "avif"]}
              />
            </Grid>
            <Grid item container xs={9}>
              <h5 style={{ marginTop: "1em", marginBottom: "0.3em" }}>
                Click and Fit
              </h5>
              <div>
                One time alignment. Once attached, it will be always aligned and
                ready for the perfect shot!{" "}
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            sx={{
              justifyContent: {
                xs: "space-between",
                sm: "flex-start",
                md: "space-between",
              },
            }}
          >
            <Grid item container xs={2}>
              <StaticImage
                src="./puzzle.svg"
                style={{ maxWidth: "100%" }}
                imgStyle={{ objectFit: "contain" }}
                loading="lazy"
                placeholder="dominantColor"
                formats={["auto", "webp", "avif"]}
              />
            </Grid>
            <Grid item container sm={6} md={9} xs={9}>
              <h5 style={{ marginTop: "1em", marginBottom: "0.3em" }}>
                Highest compatibility
              </h5>{" "}
              <div>It attaches to every slit lamp in the market. </div>{" "}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            sx={{
              justifyContent: {
                xs: "space-between",
                sm: "flex-start",
                md: "space-between",
              },
            }}
          >
            <Grid item container xs={2}>
              <StaticImage
                src="./refresh.svg"
                style={{ maxWidth: "100%" }}
                imgStyle={{ objectFit: "contain" }}
                loading="lazy"
                placeholder="dominantColor"
                formats={["auto", "webp", "avif"]}
              />
            </Grid>
            <Grid item container xs={9}>
              <h5 style={{ marginTop: "1em", marginBottom: "0.3em" }}>
                Updated system
              </h5>
              <div>
                With the help of your phone you'll always be updated with the
                best possible technology for taking pictures of your slit lamp
                diagnoses.
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          md={6}
          xs={12}
          className="image-container"
          justifyContent={"center"}
          sx={{
            marginTop: {
              xs: "2em",
              md: "0",
            },
          }}
        >
          <img
            src={first}
            style={{
              maxWidth: "100%",
              borderRadius: "10px",
              objectFit: "cover",
            }}
          ></img>
        </Grid>
      </Grid>
      <a
        href="/store/optirec"
        target="_blank"
        className="shop"
        style={{
          display: "table",
          marginTop: "5em",
          marginBottom: "5em",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "10px 45px",
        }}
      >
        BUY NOW
      </a>
    </Container>
  );
};

export default Bullets;
