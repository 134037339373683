import { Card, CardContent, Container, Grid } from "@mui/material";
import React from "react";
import "../../static/optirec.css";
import broshure from "./Latest version EN - Online Brochure - OptiREC.pdf";
import { StaticImage } from "gatsby-plugin-image";

const Bullets = () => {
  return (
    <Container style={{ marginTop: "5em", marginBottom: "5em" }}>
      <Grid item container xs={12} flexDirection={"column"}>
        <h1>Academic Publications</h1>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Card sx={{ maxWidth: 400, marginTop: "1em" }}>
            {" "}
            <a href="https://www.mdpi.com/2075-4418/13/3/423" target="_blank">
              <CardContent>
                <StaticImage
                  src="./diagnostics.png"
                  style={{ maxWidth: "100%" }}
                  imgStyle={{ objectFit: "contain" }}
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  quality={70}
                />
                <h1 style={{ marginTop: "1em" }}>
                  Smartphone Slit Lamp Imaging — Usability andQuality Assessment
                </h1>
                <div>
                  Daniel Rudolf Muth 1,* , Frank Blaser 1, Nastasia Foa 1,
                  Pauline Scherm 2, Wolfgang Johann Mayer 2,Daniel Barthelmes 1
                  and Sandrine Anne Zweifel
                </div>
              </CardContent>{" "}
            </a>
          </Card>
          <Card sx={{ maxWidth: 400, marginTop: "1em" }}>
            {" "}
            <a href={broshure} download target="_blank">
              <CardContent>
                <StaticImage
                  src="./Latest version EN - Online Brochure - OptiREC (4).png"
                  style={{ maxWidth: "100%", borderRadius: "20px" }}
                  imgStyle={{ objectFit: "contain" }}
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                />

                <h1 style={{ marginTop: "1em" }}>Download OptiREC Brochure</h1>
                <div>
                  OptiREC is a simple yet powerful tool that easily attaches to
                  your existing slit lamp. It does not need to be uninstalled
                  for you to be able to return your eye to the binoculars after
                  taking pictures.
                </div>
              </CardContent>{" "}
            </a>
          </Card>
        </div>
        <a
          href="/store/optirec"
          target="_blank"
          className="shop"
          style={{
            display: "table",
            marginTop: "5em",
            marginBottom: "5em",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "10px 45px",
          }}
        >
          BUY NOW
        </a>
      </Grid>
    </Container>
  );
};

export default Bullets;
